export const featureList = {
  showTenants: "show-tenants",
  tenantDebitNowPayment: "tenant-debit-now-payment",
  tenantBankAccountDetails: "tenant-bank-account-details",
  hideTenantRequestTab: "hide-tenant-request-tab",
  showOffers: "show-offers",
  showKolmeoPlusExploreTab: "show-kolmeoplus-tenant-explore-tab",
  showKolmeoPlusExploreService: "show-kolmeoplus-tenant-explore-service",
  showKolmeoPlusExploreDiscount: "show-kolmeoplus-tenant-explore-discounts",
  showKolmeoPlusExploreArticle: "show-kolmeoplus-tenant-explore-helpful-articles",
  showKolmeoPlusHelpfulResources: "show-kolmeoplus-tenant-helpful-resources",
  showKolmeoPlusHelpfulResourcesOwner: "show-kolmeoplus-owner-helpful-resources",
  showKolmeoPlusTenantVacateLease: "show-kolmeoplus-tenant-vacate-lease",
  showKolmeoPlusTenantVacateLeaseTile: "show-kolmeoplus-tenant-vacate-lease-tile"
} as const;
